import React, { useEffect, useRef, useState } from 'react';
import Isotope from 'isotope-layout';
import ProjectDetailsModal from './ProjectDetailsModal';
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*');
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName('html')[0];
  const isRtl = htmlElement.getAttribute('dir') === 'rtl';

  // const filters = {
  //   DETAILED: 'Details',
  //   MOCKUPS: 'Mockups',
  //   YOUTUBE: 'Youtube Videos',
  //   VIMEO: 'Vimeo Videos',
  // };

  const filters = {
    React: 'React',
    Shopify: 'Shopify',
    Python: 'Python',
    Electron: 'Electron',
  };

  const types = {
    IMAGE: 'image',
    VIDEO: 'video',
    DOCUMENT: 'document',
  };

  const projectsData = [
    // {
    //   title: 'ShareApprove',
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       'Midato Health’s ShareApprove is a platform for consent management and data collection. It integrates into established workflows, enabling form completion and signing through SMS messages with links.',
    //     client: 'Midato Health',
    //     technologies:
    //       'React, TypeScript, GraphQL, Node.js, DynamoDB, AWS (Lambda, Serverless.js), Playwright, Jest',
    //     industry: 'Health Care',
    //     date: 'Apr 2022 - Oct 2022',
    //     url: {
    //       name: 'Midato Health',
    //       link: 'https://www.midatohealth.com/shareapprove',
    //     },

    //     sliderImages: [
    //       'images/projects/project-2.jpg',
    //       'images/projects/project-5.jpg',
    //     ],
    //   },

    //   thumbImage: 'images/projects/project-1.jpg',

    //   categories: [filters.React],
    // },
    {
      title: 'Luxe Barbeque (E-commerce)',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'Luxe Barbeque is known for its passion for BBQ, food, and customer service. In this project, In this project, I took ownership of the entire development process, collaborating with another developer and a designer to build this e-commerce site on Shopify. The site features a fully custom theme created from scratch, based directly on our designs',
        challenge:
          'Luxe aims to offer an immersive shopping experience with dynamic filters and a seamless search function across all collections and the Find Perfect Grill page. This involves rendering over 5000 products simultaneously and dynamically updating product listings based on filter selections without reloading the page. However, there are two main challenges: 1. Shopify API has strict limitations, allowing only 250 products per fetch. 2. Pagination in Shopify Liquid causes the page to reload by default',
        solution:
          'To overcome these challenges, we implemented a solution using JavaScript to handle product fetching and rendering dynamically, storing product data in local storage to ensure fast and responsive filtering and searching, mimicking a single-page application behavior',
        technologies:
          'JavaScript, Shopify, Liquid, HTML, CSS, SCSS, Bootstrap, jQuery, AJAX, JSON',
        industry: 'E-commerce',
        url: {
          name: 'Luxe Barbeque',
          link: 'https://luxebbq.ca',
        },

        sliderImages: [
          'images/projects/luxe/luxe-bbq-1.png',
          'images/projects/luxe/luxe-bbq-2.png',
          'images/projects/luxe/luxe-bbq-3.gif',
          'images/projects/luxe/luxe-bbq-4.gif',
        ],
      },
      thumbImage: 'images/projects/luxe/luxe-bbq-0.png',
      categories: [filters.Shopify],
    },
    {
      title: 'Coursera Trophy Generator',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'A full-stack app that generates a shareable link to display all of your Coursera certifications as visual SVG trophies on a single page',
        challenge:
          'Coursera does not offer a consolidated link for certificates, nor does its API support this functionality. limiting developers from showcasing their continuous learning achievements from one of the most reputable online education platforms',
        solution:
          'My tool addresses this by allowing users to input their certification IDs, after which the backend generates a consolidated SVG of all certifications earned. This means you can easily share your achievements anywhere—embed them in a GitHub README or any website using just one link.',
        technologies: 'Node, React, Express, Tailwind CSS',
        industry: 'Open Source Project',
        url: {
          name: 'Generator App',
          link: 'https://github-coursera-trophy-app.vercel.app',
        },
        sliderImages: [
          'images/projects/coursera-trophy/app.gif',
          'images/projects/coursera-trophy/generated_link.png',
          'images/projects/coursera-trophy/usage.png',
        ],
      },
      thumbImage: 'images/projects/coursera-trophy/app.gif',
      categories: [filters.React],
    },
    {
      title: 'Photos Sync Tool Mac Desktop App',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "My friend is a photographer who uses a camera model that doesn't sync JPG and RAW files accordingly. She wants to sync all RAW files with keeper JPEGs from a shoot, but it takes a lot of time manually comparing both folders and deleting files.",
        challenge:
          'Need a solution that is easy for a non-technical user to operate',
        solution:
          'I created a Mac desktop version of a tool built with the Electron framework. Previously, I created a version using Python and bash scripts, which was not ideal for non-tech users.  \n\nI was able to create these simple scripts within 3 hours, saving her hours of work every week.',
        technologies:
          'Electron, JavaScript, HTML, Tailwind CSS, Node.js, Python, Bash Scripting',
        industry: 'Open Source Project',
        url: {
          name: 'Git Repo',
          link: 'https://github.com/dza59/photo-sync-tool-mac-app-version',
        },

        sliderImages: ['images/projects/synctool/sync-app.png'],
      },
      thumbImage: 'images/projects/synctool/sync-app.png',
      categories: [filters.Electron],
    },
    {
      title: 'More Coming Soon',
      type: types.IMAGE,
      document: {
        projectInfo: '',
        challenge: 'JS filter',
        technologies: '',
        industry: '',
        url: {
          name: 'www.example.com',
          link: 'https://dannizhang.me',
        },
        sliderImages: [
          'images/projects/project-1.jpg',
          'images/projects/project-2.jpg',
        ],
      },
      thumbImage: 'images/coming.jpg',
      categories: [filters.React],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope('.portfolio-filter', {
      itemSelector: '.filter-item',
      layoutMode: 'masonry',
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === '*'
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(' ');
    }
  };

  return (
    <>
      <section id='portfolio' className={'section bg-body-tertiary'}>
        <div className={'container'}>
          {/* Heading */}
          <div className='row mb-5 wow fadeInUp'>
            <div className='col-lg-9 col-xl-8 mx-auto text-center'>
              <h2 className='fw-600 mb-3'>Projects</h2>
              <hr className='heading-separator-line border-primary border-2 opacity-10 mx-auto' />
              {/* <p className='text-4 text-muted'>
                I help you build brand for your business at an affordable price.
                Thousands of clients have procured exceptional results while
                working with Me.
              </p> */}
            </div>
          </div>

          {/* Heading end*/}
          {/* Filter Menu */}
          <ul className='portfolio-menu nav nav-pills text-uppercase justify-content-center border-bottom-0 mb-5 wow fadeInUp'>
            <li className='nav-item'>
              <button
                className={
                  'nav-link rounded-pill ' + (filterKey === '*' ? 'active' : '')
                }
                onClick={handleFilterKeyChange('*')}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className='nav-item' key={i}>
                <button
                  className={
                    'nav-link rounded-pill ' +
                    (filterKey === oneKey ? 'active' : '')
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className='portfolio wow fadeInUp'>
            <div className='row portfolio-filter filter-container g-4'>
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      'col-sm-6 col-lg-4 filter-item ' +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className='portfolio-box rounded'>
                      <div className='portfolio-img rounded'>
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className='img-fluid d-block portfolio-image'
                          src={project.thumbImage}
                          alt=''
                        />
                        <div
                          className='portfolio-overlay'
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className='popup-ajax stretched-link border-0 p-0 '></button>
                          <div className='portfolio-overlay-details'>
                            {/* <p className='text-white text-6'>
                              {project.type === types.DOCUMENT && (
                                <i className='fas fa-file-alt'></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className='fas fa-image'></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className='fas fa-video'></i>
                              )}
                            </p> */}
                            <h5 className='text-white fw-400'>
                              {project?.title}
                            </h5>
                            {/* <span className='text-light'>Category</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
