import React from 'react';
// import resumeFile from "../documents/resume.pdf";
// import { WorkProcessDetails } from '../config/dataConfig';
import { CertificationsDetails } from '../config/dataConfig';

const Certifications = () => {
  return (
    <section id='certifications' className='section '>
      <div className='container'>
        <div className='row mb-5 wow fadeInUp'>
          <div className='col-lg-9 col-xl-8 mx-auto text-center'>
            <h2 className='fw-600 mb-3'>
              {CertificationsDetails.heading.title}
            </h2>
            <hr className='heading-separator-line border-primary border-2 opacity-10 mx-auto' />
          </div>
        </div>
        {/* Heading End */}
        <div className='row'>
          <div className='col-xl-10 mx-auto'>
            <div className='row gy-5'>
              {CertificationsDetails.WDetails.map(
                ({ sno, name, link, issuer, icon, outcomes }) => (
                  <div key={name} className='col-md-6 wow fadeInUp'>
                    <div className='featured-box style-3'>
                      <div className='featured-box-icon text-primary border border-primary border-3 rounded-circle'>
                        <i className={icon} />
                      </div>
                      <h3 className='text-5'>{name}</h3>
                      <p className='text-3 mb-0'>
                        Issued by <strong>{issuer}</strong>{' '}
                        <a href={link} target='_blank' rel='noreferrer'>
                          Show credential
                        </a>
                      </p>
                      <ul>
                        {outcomes.map(({ text }, index) => (
                          <li key={index}>{text}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certifications;
