export const headerConfig = {
  Default: 'Default Header',
  Classic: 'Classic Header',
  BottomHeader: 'Bottom Header',
  MenuPushOpenSideHeader: 'Menu Push Open Side Header',
  OverlayMenu: 'Overlay Menu',
  SideHeader: 'Side Header',
};

export const introBackgroundConfig = {
  particles: 'particles',
  image: 'image',
  video: 'video',
};

export const appliedConfig = {
  // apply Header from : Default | Classic | BottomHeader | MenuPushOpenSideHeader | OverlayMenu | SideHeader
  appliedHeader: headerConfig.SideHeader,

  // dark and light header option only work with Classic | BottomHeader | SideHeader appliedHeader options, choose true if you want to apply a dark and false for light header
  isDarkHeader: true,

  // apply Intro from :  image | video | particles
  appliedIntro: introBackgroundConfig.particles,

  // apply primary background to intro section
  isPrimaryBackground: true,

  // apply Frame Layout
  isFrameLayout: false,

  // apply Dark Theme template
  isDarkTheme: true,
};

export const scrollDuration = 1000;
