import { Link } from 'react-scroll';

export const IntroSecDetails = {
  heading1: 'HELLO WORLD',
  typeWritterHeadings: [
    "I'm Danni Zhang",
    "I'm a Software Engineer",
    'Outdoor Enthusiast',
    'Lifelong Learner',
  ],
  heading2: 'based in Vancouver, BC, Canada',
  btnText: 'Download CV',
};
export const AboutSecDetails = {
  image: {
    img: 'images/about-me.jpg',
  },
  information: {
    title1: 'About Me',
    name: 'Danni Zhang',
    desc1:
      '      I am a dedicated Software Engineer driven by innovation, passion, and curiosity. Proficient in collaborating with cross-functional team members, entrepreneurs, and clients, I am skilled at analyzing and comprehending project scope and am always committed to finding solutions, no matter what challenges arise.',
    desc2:
      " I'm often referred to as the 'go-to' person by my colleagues in all the companies I've worked for. I also deeply value contributing to company growth as both a mentor and mentee, as I firmly believe that If you want to walk fast, walk alone. But if you want to walk far, walk together.",
    story:
      "I recently graduated from Simon Fraser University with a degree in computer science. Before returning to university, I had over 4+ years of working experience in software development, where I developed a passion for using cutting-edge technologies. In 2020, I decided to take a break from my job and go back to university to formalize my education in CS and plan for long-term growth. I value software architecture for its scalability and supportability, and I deeply believe in the long-term benefits of understanding the foundations of CS, including Math, Stats, Hardware, and Data Structures & Algorithms. While some questioned why I didn't continue gaining experience at work, I believe that what I want to become might come later but investing in education will never be lost.",
  },
  moreAbout: [
    {
      num: 4,
      title: 'Front End Dev',
    },
    {
      num: 2,
      title: 'Full Stack Dev',
    },
    {
      num: 10000,
      title: 'Shopify Stores (Bugs Fix & Customization)',
    },
    // {
    //   num: 780,
    //   title: 'Projects Done',
    // },
  ],
};

export const ServicesDetails = {
  heading: {
    title: 'Our Services',
    text: 'How I can help take your next project to new heights! Thousands of clients have procured exceptional results while working with Me.',
  },
  details: [
    {
      name: 'Graphic Design',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.',
      icon: 'fas fa-palette',
    },
    {
      name: 'Web Design',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.',
      icon: 'fas fa-desktop',
    },
    {
      name: 'UI/UX Design',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.',
      icon: 'fas fa-pencil-ruler',
    },
    {
      name: 'App Design & Develop',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.',
      icon: 'fas fa-paint-brush',
    },
    {
      name: 'Business Analysis',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.',
      icon: 'fas fa-chart-area',
    },
    {
      name: 'SEO Marketing',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.',
      icon: 'fas fa-bullhorn',
    },
  ],
};

export const MyWorking_Skills = {
  heading: {
    title: 'Why Me?',
    text: '',
  },
  video: {
    vdo: '',
  },
  working: [
    {
      name: 'Flexibility',
      icon: 'fa-regular fa-hat-cowboy fas fa-cog fa-expand-al',
      desc: 'I am extremely flexible and adept at switching between tasks, responsibilities, or tech stacks as needed. I adapt quickly to meet the requirements of the team, business, or client. With web apps iteration moving rapidly, I am very passionate about testing new tools and tech as part of my job',
    },
    {
      name: 'Caring',
      icon: 'fas fa-heart',
      desc: 'I place a high value on customer satisfaction. Recognized by my colleagues for delivering the highest quality of service, I focus not only on the quality of the products I build but also on what I can do to benefit both the company I work for and our clients/users',
    },
    {
      name: 'Problem-Solving',
      icon: 'fas fa-search',
      desc: 'I possess strong problem solving skills and am known for my quick turnaround time. My experience in fixing bugs in Shopify stores and customizing themes & apps has enhanced my troubleshooting abilities and my proficiency in researching new tools/solutions. For Shopify merchants, time is crucial, as every minute = $$',
    },
    {
      name: 'Teamwork',
      icon: 'fas fa-comments',
      desc: 'I deeply value contributing to company growth both as a mentor and as a mentee. I firmly believe in the saying, If you want to walk fast, walk alone. But if you want to walk far, walk together',
    },
    {
      name: 'Learning',
      icon: 'fas fa-book',
      desc: 'Less work experience with your tech stack? No problem. I am eager and confident in my ability to learn quickly. How can I prove it? You can test me during the interview, or I can build a project to demonstrate',
    },
    {
      name: 'Humor',
      icon: 'far fa-smile-wink',
      desc: 'Others say that, and I agree ( ͡° ͜ʖ ͡°)',
    },
    {
      name: 'Bold',
      icon: 'fas fa-user-ninja',
      desc: 'A woman in tech who loves riding motorcycle, playing drums, and hiding in nature',
    },
    {
      name: '',
      icon: '',
      desc: '',
    },
    {
      name: '',
      icon: '',
      desc: '',
    },
  ],
  skills: [
    {
      skill: 'JavaScript & TypeScript',
      percent: 90,
    },
    {
      skill: 'HTML&CSS',
      percent: 95,
    },
    {
      skill: 'Python',
      percent: 80,
    },
    {
      skill: 'PHP & MYSQL',
      percent: 80,
    },
    {
      skill: 'JAVA',
      percent: 55,
    },
    {
      skill: 'C++',
      percent: 55,
    },
    {
      skill: 'Racket',
      percent: 40,
    },
    {
      skill: 'Haskell',
      percent: 40,
    },
  ],
  skills2: [
    {
      skill: 'React',
      percent: 85,
    },
    {
      skill: 'Node.js',
      percent: 75,
    },
    {
      skill: 'Next.js',
      percent: 75,
    },
    {
      skill: 'Express.js',
      percent: 70,
    },
    {
      skill: 'Laravel',
      percent: 55,
    },
    {
      skill: 'Langchain',
      percent: 50,
    },
    {
      skill: 'Postgres',
      percent: 70,
    },
    {
      skill: 'MongoDB',
      percent: 70,
    },
    {
      skill: 'GraphQL || Restful API',
      percent: 80,
    },

    {
      skill: 'AWS (lambda, serverless)',
      percent: 30,
    },
    {
      skill: 'Git',
      percent: 95,
    },
  ],
};

export const CallToActionDetails = {
  heading: 'Connect with Me today!',
  subHeading: 'I am currently exploring new opportunities.',
  btnText: 'Chat',
};

export const RecentActivityDetails = {
  heading: {
    title: 'Recent Activity',
    text: '',
  },
  WDetails: [
    {
      sno: 1,
      name: 'Seeking a full time job',
      desc: 'My priority right now is to seek a full-time job as a software engineer that utilizes the modern web stacks. At the same time, continue learning to further develop my skills. Lastly, Take it easy and NEVER GIVE UP!',
    },
    {
      sno: 2,
      name: 'Learning GCP',
      desc: 'I am currently pursuing the Associate Cloud Engineer Certification. Cloud computing skills are in high demand in the job market, also enable me to build scalable solutions based on business needs',
    },
    {
      sno: 3,
      name: 'Learning LangChain',
      desc: "Explore different AI using LangChain. Recently, I've been learning by making things like a chatbot with LangChain and OpenAI, using Gemini for recognizing images, and playing around with AWS boto3 SDK to try out things like Comprehend, Rekognition, and Transcribe. It's been fun learning and might lead to some cool projects",
    },
    {
      sno: 4,
      name: 'Mastering Back-End skills',
      desc: 'Studying the Meta Back-End Developer Course on Coursera will enable me to obtain a Professional Certificate',
    },
    {
      sno: 5,
      name: 'WishLot Project',
      desc: "WishLot is my personal project. I thought of it last month when I was trying to pick a gift for my partner but didn't know what he wanted. I wanted to get something he actually wanted, so it wouldn't end up collecting dust. That's where I got the WishLot App idea from: a platform where users can log what they want and share their wishlists with friends and family. Users can rate how likely they are to want each item",
    },
    {
      sno: 6,
      name: 'Mock Interviewer Project',
      desc: "The AI Mock Interviewer Project is an idea my buddy and I are currently working on. We've planned out various phrases and intend to roll them out gradually. The idea is to create a web app where users can upload their resume and a job posting. Then, the AI Interviewer will conduct a mock interview with the user based on the provided info. The goal of the app is to help new grads practice their interview skills and build their confidence, helping them and myself (•‿•)",
    },
  ],
};

export const CertificationsDetails = {
  heading: {
    title: 'Certifications',
    text: '',
  },
  WDetails: [
    {
      sno: 1,
      name: 'Introduction to Machine Learning on AWS',
      link: 'https://www.coursera.org/account/accomplishments/verify/XANTYGAD4JM4',
      icon: 'fab fa-aws',
      issuer: 'AWS',
      outcomes: [
        {
          text: 'Differentiate between artificial intelligence (AI), machine learning, and deep learning',
        },
        {
          text: 'Select the appropriate AWS machine learning service for a given use case',
        },
        {
          text: 'Discover how to build, train, and deploy machine learning models',
        },
      ],
    },
    {
      sno: 2,
      name: 'Introduction to Back-End Development',
      link: 'https://www.coursera.org/account/accomplishments/verify/RBGLLZXRW7TA',
      icon: 'fab fa-facebook',
      issuer: 'Meta',
      outcomes: [
        {
          text: 'This course is part of the Meta Back-End Developer Professional Certificate',
        },
      ],
    },
    {
      sno: 3,
      name: 'Programming in Python',
      link: 'https://www.coursera.org/account/accomplishments/verify/X93MXETCCH3S',
      icon: 'fab fa-facebook',
      issuer: 'Meta',
      outcomes: [
        {
          text: 'This course is part of the Meta Back-End Developer Professional Certificate',
        },
      ],
    },
  ],
};

export const WorkProcessDetails = {
  heading: {
    title: 'Work Process',
    text: 'Simple work process of build your design. Delivering work within time and budget which meets client’s requirements is our moto.',
  },
  WDetails: [
    {
      sno: 1,
      name: 'Planning & Consulting',
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 2,
      name: 'Content',
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 3,
      name: 'Conception',
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 4,
      name: 'Design & Development',
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 5,
      name: 'Final discussion',
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 6,
      name: 'Delivery & Launch',
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
  ],
};

export const OurClientsDetails = {
  heading: {
    title: 'Testimonials',
    text: 'See what my colleagues and lead say about me',
  },
  clients: [
    {
      name: 'Jay Shah',
      img: 'images/testimonial/client-sm-1.jpg',
      exp: 'Founder at Icomatic Pvt Ltd',
      summary:
        ' Easy to use, reasonably priced simply dummy text of theprinting and typesetting industry. Quidam lisque persiusinteresset his et, in quot quidam possim iriure. simply dummy text of the printing and typesetting industry.',
    },
    {
      name: 'Patric Cary',
      img: 'images/testimonial/client-sm-2.jpg',
      exp: 'Freelancer from USA',
      summary:
        ' “I am happy Working with printing and typesetting industry.Quidam lisque persius interesset his et, in quot quidampersequeris essent possim iriure. simply dummy text of theprinting and typesetting industry.”',
    },
    {
      name: 'Dennis Jacques',
      img: 'images/testimonial/client-sm-3.jpg',
      exp: 'iDeal Inc',
      summary:
        ' “Only trying it out since a few days. But up to now excellent.Seems to work flawlessly. priced simply dummy text of theprinting and typesetting industry.”',
    },
    {
      name: 'Chris Tom',
      img: 'images/testimonial/client-sm-4.jpg',
      exp: 'Company CEO from UK',
      summary:
        ' “I have used them twice now. Good rates, very efficientservice and priced simply dummy text of the printing andtypesetting industry quidam interesset his et. simply dummy text of the printing and typesetting industry. Excellent.”',
    },
  ],
  posters: [
    {
      id: 1,
      img: 'images/clients/client-logo2.png',
    },
    {
      id: 2,
      img: 'images/clients/client-logo3.png',
    },
    {
      id: 3,
      img: 'images/clients/client-logo1.png',
    },
    {
      id: 4,
      img: 'images/clients/client-logo2.png',
    },
    {
      id: 5,
      img: 'images/clients/client-logo3.png',
    },
    {
      id: 6,
      img: 'images/clients/client-logo1.png',
    },
    {
      id: 7,
      img: 'images/clients/client-logo3.png',
    },
    {
      id: 8,
      img: 'images/clients/client-logo1.png',
    },
    {
      id: 9,
      img: 'images/clients/client-logo2.png',
    },
    {
      id: 10,
      img: 'images/clients/client-logo3.png',
    },
    {
      id: 11,
      img: 'images/clients/client-logo1.png',
    },
    {
      id: 12,
      img: 'images/clients/client-logo2.png',
    },
  ],
};

export const ContactDetails = [
  // {
  //   icon: 'fas fa-map-marker-alt',
  //   title: 'Visit Us',
  //   p1: '145 Murphy Canyon Rd.',
  //   p2: ' Suite 100-18, San Diego CA 2028',
  // },
  // {
  //   icon: 'fas fa-phone-alt',
  //   title: 'Call  Now',
  //   p1: 'Phone: (+060) 9898980098',
  //   p2: ' Fax: (+060) 8898880088',
  // },
  {
    icon: 'fas fa-envelope',
    title: '',
    p1: 'zdn.zhang@gmail.com',
    p2: ' ',
  },
];

export const socialIcons = [
  {
    title: 'Linkedin',
    icon: 'fab fa-linkedin-in',
    href: 'https://www.linkedin.com/in/zhangdanni',
    className: 'social-icons-linkedin',
  },
  {
    title: 'GitHub',
    icon: 'fab fa-github',
    href: 'https://github.com/dza59',
    className: 'social-icons-github',
  },
  {
    title: 'instagram',
    icon: 'fab fa-instagram',
    href: 'https://www.instagram.com/zdnblue',
    className: 'social-icons-instagram',
  },
];
